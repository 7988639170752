<template>
  <div>
    <van-cell-group title="填写详情">
      <van-field readonly label="分类" placeholder="请选择分类" is-link @click="cat.show=true" v-model="cat.txt" />
      <van-popup v-model="cat.show" round  position="bottom">
        <van-cascader title="请选择分类" :options="cat.ls" @finish="catSel" @close="cat.show=false" v-model="model.BODY.CAT_ID"
                      :field-names="{text:'NAME',value:'ID',children:'CHILDREN'}" />
      </van-popup>
      <van-field readonly label="物品" placeholder="请选择物品" is-link @click="stock.show=true" v-model="stock.txt" />
      <van-popup v-model="stock.show" round  position="bottom">
        <van-search @search="onStockSearch" v-model="stock.key" />
        <van-picker :columns="stock.lss" show-toolbar @cancel="stock.show=false" @confirm="stockChange">
          <template slot-scope="scope" slot="option">
            <van-cell :title="scope.NAME" :value="scope.BALANCE" />
          </template>
        </van-picker>
      </van-popup>
      <van-field v-model="model.BODY.CNT" type="digit" label="数量" placeholder="请输入数量" />
      <van-field readonly label="所属班级" placeholder="请选择班级" is-link @click="cla.show=true" v-model="model.BODY.CLASS_NAME" />
      <van-popup v-model="cla.show" round  position="bottom">
        <van-picker :columns="cla.ls" show-toolbar @cancel="cla.show=false" @confirm="classSel" value-key="NAME" />
      </van-popup>
      <van-field v-model="model.BODY.DESCRIPTION" type="textarea" label="备注" :rows="2" />
    </van-cell-group>
    <div style="margin: 15px">
      <van-button type="primary" size="large" icon="success" block @click="submit" :loading="ret.loading" loading-text="正在提交。。。">确认提交</van-button>
      <br />
      <van-button plain type="default" size="large" icon="cross" block to="/wfl">取消返回</van-button>
    </div>
  </div>
</template>
<style scoped>
</style>
<script>
export default {
  name: "new",
  data() {
    return {
      model: {
        CONF_SN:"mat_apply",
        BODY:{}
      },
      cat:{
        show:false,
        ls:[],
        txt:""
      },
      stock:{
        show:false,
        ls:[],
        txt:"",
        lss:[],
        key:""
      },
      ed_show:false,
      cla:{
        show:false,
        ls:[],
      },
      ret:{
        loading:false
      }
    }
  },
  created() {
    this.getCat();
    this.getClass();
  },
  methods:{
    getCat(){
      let self = this;
      this.whale.remote.getResult({
        url: "/api/Mobile/MAT/MCatApi/GetTree",
        completed: function (its) {
          self.cat.ls=its.DATA;
        }
      })
    },
    catSel({ selectedOptions}){
      this.cat.show=false;
      this.cat.txt = selectedOptions.map((option) => option.NAME).join('/');
      this.getStock();
    },
    getStock(){
      let self=this;
      this.whale.remote.getCollection({
        url:"/api/Mobile/MAT/MStockApi/GetList",
        data: {CAT_ID:this.model.BODY.CAT_ID},
        completed:function (its){
          self.model.BODY.ST_ID="";
          self.stock.ls=its.ITEMS;
          self.stock.lss=its.ITEMS;
        }
      })
    },
    stockChange(o){
      this.stock.show=false;
      this.stock.txt=o.NAME;
      this.model.STOCK=o;
      this.model.TITLE=o.NAME;
      this.model.BODY.ST_ID=o.ID;
      this.model.BODY.PRICE=o.PRICE;
    },
    onStockSearch(v){
      this.stock.lss=this.stock.ls.filter(o=>{
        return o.NAME.indexOf(v)>=0
      })
    },
    getClass(){
      let self=this;
      this.whale.remote.getCollection({
        url: "/api/Mobile/ORG/ClassApi/GetList",
        completed: function (its) {
          self.cla.ls = its.ITEMS;
        }
      })
    },
    classSel(o){
      this.model.BODY.CLASS_ID=o.ID;
      this.model.BODY.CLASS_NAME=o.NAME;
      this.cla.show=false;
    },
    submit(){
      if (this.model.BODY.CNT>this.model.STOCK.BALANCE){
        this.whale.toast.err("数量不能超过库存量")
        return
      }
      if (this.model.BODY.CLASS_ID!=undefined && this.model.BODY.CLASS_ID!=""){
        for(let i=0;i<this.cla.ls.length;i++){
          if (this.model.BODY.CLASS_ID==this.cla.ls[i].ID){
            this.model.BODY.CLASS_NAME=this.cla.ls[i].NAME;
          }
        }
      }
      this.model.DESCRIPTION=this.model.BODY.CNT+"("+this.model.STOCK.FUNIT+")"

      let self=this;
      self.ret.loading=true;
      this.whale.remote.getResult({
        url:"/api/Mobile/WFL/ActApi/Save",
        data:this.model,
        finally(){
          self.ret.loading=false;
        },
        completed(){
          self.$dialog.alert({
            title:"完成",
            message:"记录已成功提交！",
            theme:"round",
          }).then(()=>{self.$router.go(-1);})
        }
      })
    }
  }
}
</script>

